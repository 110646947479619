export function hideHeader(){
    let toolbar = document.getElementsByClassName( 'toolbar' );
    toolbar[0].classList.add('-translate-y-full');

    let hdr = document.getElementsByClassName( 'hdr' );
    hdr[0].classList.remove('lg:bg-transparent');
    hdr[0].classList.add('lg:bg-blue');
}

export function showHeader(){
    let toolbar = document.getElementsByClassName( 'toolbar' );
    toolbar[0].classList.remove('-translate-y-full');

    let hdr = document.getElementsByClassName( 'hdr' );
    hdr[0].classList.remove('lg:bg-blue');
    hdr[0].classList.add('lg:bg-transparent');
}


// export function hideBackToTop(){
//     let backTop = document.getElementsByClassName( 'back_to_top' );
//     backTop[0].classList.add('translate-x-28');

// }

// export function showBackToTop(){
//     let backTop = document.getElementsByClassName( 'back_to_top' );
//     backTop[0].classList.remove('translate-x-28');
// }