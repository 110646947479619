import { hideHeader, showHeader, hideBackToTop, showBackToTop  } from './tools.js';

/* --------------------------------------------------------------------
Hide header bar on scroll.
Reveal after period of inactivity.
-------------------------------------------------------------------- */
let hideHeaderTriggerPoint = 100;
var isScrolling, isWaiting, lastScrollPos;

window.addEventListener('scroll', function(){
    // Clear our timeout throughout the scroll
    window.clearTimeout( isScrolling );
    window.clearTimeout( isWaiting )

    // Simplified Header scroller
    if(getYPosition() >= hideHeaderTriggerPoint){
        hideHeader();
    }
    else{
        showHeader();
    }

    lastScrollPos = getYPosition();
});


function getYPosition(){
    var top  = window.pageYOffset || document.documentElement.scrollTop
    return top;
}

/* --------------------------------------------------------------------
Anchor links with the anchor class to the assigned target.
Requires .anchor class on a link and valud href #hash
-------------------------------------------------------------------- */
let anchors = document.getElementsByClassName('anchor');

for (var i = anchors.length - 1; i >= 0; i--) {

    anchors[i].addEventListener('click', function(e){
        e.preventDefault();

        let link = e.target;
        let anchorTarget = link.hash.replace('#','');

        if(anchorTarget){
            let header = document.getElementsByClassName( 'hdr' );
            let destination = document.getElementById( anchorTarget );
            let additionalPadding = 20;

            document.body.parentNode.scrollTo({
                top: destination.offsetTop - (header[0].offsetHeight + additionalPadding),
                left: 0,
                behavior: 'smooth'
            });
        }
        else{
            console.error('The link ['+link+'] has an anchor class but the href is not a valid hash.')
        }

    });
};

/* --------------------------------------------------------------------
Adjust header height when hovering over main nav dropdown
-------------------------------------------------------------------- */
let menuDropdown = document.getElementsByClassName('menu-item-has-children');
let hdr = document.getElementsByClassName('hdr');

for (var i = menuDropdown.length - 1; i >= 0; i--) {

    menuDropdown[i].parentNode.addEventListener("mouseenter", (t) =>{
        if( hdr[0] ){
            hdr[0].classList.add('lg:bg-blue');
            hdr[0].classList.remove('lg:h-auto');
            hdr[0].classList.remove('lg:bg-transparent');
            hdr[0].classList.remove('h-screen');
            hdr[0].style.height = (hdr[0].offsetHeight + t.target.children[0].nextElementSibling.offsetHeight) + 'px';
        }
    });

    menuDropdown[i].parentNode.addEventListener("mouseleave", (t) =>{

        if(getYPosition() < hideHeaderTriggerPoint){
            hdr[0].classList.remove('lg:bg-blue');
            hdr[0].classList.add('lg:bg-transparent');
        }

        hdr[0].classList.add('lg:h-auto');
        hdr[0].classList.add('h-screen');
        hdr[0].removeAttribute('style');
    });
}


// offsetTop 44
// offsetHeight 64